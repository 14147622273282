import { render, staticRenderFns } from "./HrDbPage.vue?vue&type=template&id=1185409c&scoped=true&"
import script from "./HrDbPage.vue?vue&type=script&lang=ts&"
export * from "./HrDbPage.vue?vue&type=script&lang=ts&"
import style0 from "./HrDbPage.vue?vue&type=style&index=0&id=1185409c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1185409c",
  null
  
)

export default component.exports
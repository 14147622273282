

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Component, {mixins} from 'vue-class-component';
import {Vue, Watch} from 'vue-property-decorator';
import _ from 'lodash';
import AXIOS from '@/services/axios-wrapper';
import moment from 'moment';
import {HrDbUserDto, HrDetail, HrCareer, HrAcademic, HrCertificate, HrLanguage, HrDbUserInitPWDto} from '@/model-hr';
import GeneralUtil from '@/util/GeneralUtil';
import {BV_OptionItem, FormUploadFiles} from '@/models';
import {BizSirenResultDto} from '@/services/LoginModels';
import {loginService} from '@/services/login.service';
import FormAttachHrFiles from '@/components/FormAttachHrFiles.vue';

interface ValdateComponentType extends Vue {
  validate(): any;
}

@Component({
  components: {
    GeneralUtil,
    FormAttachHrFiles,
  },
})
export default class HrDbPage extends mixins(GeneralUtil) {
  $refs!: {
    observer2: ValdateComponentType;
    observer: ValdateComponentType;
  };
  agree1 = false;
  agree2 = false;
  agree3 = false;
  agree4 = false;
  private text1 =
    'SK PINX 개인정보처리방침\n \nSK PINX(thepinx.co.kr, 이하 PINX)이(가) 취급하는 모든 개인정보는 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하여 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.\n';
  private text2 =
    '1. 개인정보 수집 및 이용주체\n \n本 채용홈페이지를 통해 제출하는 지원서는 귀하께서 지원서를 제출하는 회사(이하 채용회사)가 직접 접수하고 관리하며, 향후 지원서 관리책임은 채용회사에 있습니다.\n\n 2. 동의를 거부할 권리 및 동의 거부에 따른 불이익\n' +
    '지원자는 아래 개인정보 제공 등에 관해 동의하지 않을 권리가 있습니다.\n' +
    '다만, 지원서를 통해 제공받는 정보는 회사의 채용 전형에 필수적인 항목으로 해당 정보를 제공받지 못할 경우 회사는 공정한 선발전형을 진행할 수 없습니다.\n' +
    '따라서 개인정보 제공에 대해 동의하지 않는 경우 채용 전형에 지원이 제한될 수 있습니다.\n' +
    '지원자의 동의 거부 권리 및 동의 거부에 따른 불이익은 아래에 제시되는 모든 동의사항에 해당됩니다.';

  hrUserDtoNew: HrDbUserDto = {
    kor_nm: '',
    biryymm_dt: '',
    email_nm: '',
    sex_cd: 'm',
    scret_no: '',
    hp_no: '',
  };

  hrUserDto: HrDbUserDto = {
    kor_nm: '',
    biryymm_dt: '',
    email_nm: '',
    sex_cd: 'M',
    scret_no: '',
    hp_no: '',
  };

  hrUserDtoInitPW: HrDbUserInitPWDto = {
    kor_nm: '',
    biryymm_dt: '',
    email_nm: '',
    sex_cd: '',
    hp_no: '',
  };
  hrDetailDto: HrDetail = {
    kor_nm: '',
    hp_no: '',
    email_nm: '',
    biryymm_dt: '',
    sex_cd: '',
    jnco_afe_no: '',
    eng_lnm_nm: '',
    ntnl_nm: '',
    fg_nm: '',
    tel_no: '',
    duty_dc: '',
    file_dc: '',
    originalFilename: '',
    originalExtension: '',
    career: [],
    academic: [],
    certificate: [],
    language: [],
  };
  v_datepicker_masks = {
    input: 'YYYY-MM-DD',
  };
  fields_academic: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'from_dt',
      label: '입학년월',
    },
    {
      key: 'to_dt',
      label: '졸업년월',
      //thClass: 'red',
    },
    {
      key: 'mcls_dc1',
      label: '학교명',
    },
    {
      key: 'mcls_dc2',
      label: '학과(전공)',
    },
    {
      key: 'mcls_dc3',
      label: '학년',
    },
    {
      key: 'mcls_dc4',
      label: '졸업여부',
    },
    {
      key: 'action',
    },
  ];
  fields_career: any[] = [
    {
      key: 'index',
      label: '#',
    },
    // {
    //   key: 'from_dt',
    //   label: '시작년월',
    // },
    // {
    //   key: 'to_dt',
    //   label: '마침년월',
    //   //thClass: 'red',
    // },
    {
      key: 'period',
      label: '기간',
    },
    {
      key: 'mcls_dc1',
      label: '회사명',
    },
    {
      key: 'mcls_dc2',
      label: '직위',
    },
    {
      key: 'mcls_dc3',
      label: '직무',
    },
    {
      key: 'mcls_dc5',
      label: '최종연봉',
    },
    {
      key: 'mcls_dc4',
      label: '소재지',
    },
    {
      key: 'action',
    },
  ];
  fields_certificate: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'from_dt',
      label: '취득일',
    },
    {
      key: 'mcls_dc1',
      label: '종류',
    },
    {
      key: 'mcls_dc2',
      label: '등급',
    },
    {
      key: 'mcls_dc3',
      label: '발급기관',
    },
    {
      key: 'action',
    },
  ];
  fields_language: any[] = [
    {
      key: 'index',
      label: '#',
    },
    {
      key: 'from_dt',
      label: '취득일',
    },
    {
      key: 'mcls_dc1',
      label: '종류',
    },
    {
      key: 'mcls_dc2',
      label: '점수',
    },
    {
      key: 'mcls_dc3',
      label: '발급기관',
    },
    {
      key: 'action',
    },
  ];
  options_job_Category: BV_OptionItem[] = [
    {value: 'HR', text: 'HR'},
    {value: '재무회계', text: '재무회계'},
    {value: '경리출납', text: '경리출납'},
    {value: '총무', text: '총무'},
    {value: '구매', text: '구매'},
    {value: 'IT', text: 'IT'},
    {value: '운전', text: '운전'},
    {value: 'RM', text: 'RM'},
    {value: '세일즈', text: '세일즈'},
    {value: '홍보', text: '홍보'},
    {value: '기획', text: '기획'},
    {value: '서버', text: '서버'},
    {value: '조리사', text: '조리사'},
    {value: '찬모', text: '찬모'},
    {value: '찬모보조', text: '찬모보조'},
    {value: '주방보조', text: '주방보조'},
    {value: '파티셰', text: '파티셰'},
    {value: '행정사무', text: '행정사무'},
    {value: '프런트', text: '프런트'},
    {value: '기계관리', text: '기계관리'},
    {value: '시설관리', text: '시설관리'},
    {value: '전기관리', text: '전기관리'},
    {value: '컨시어지', text: '컨시어지'},
    {value: '객실관리', text: '객실관리'},
    {value: '컨시어지매니저', text: '컨시어지매니저'},
    {value: '안전요원', text: '안전요원'},
    {value: '프로샵', text: '프로샵'},
    {value: '회원관리', text: '회원관리'},
    {value: '카트관리', text: '카트관리'},
    {value: '경기진행', text: '경기진행'},
    {value: '조경관리', text: '조경관리'},
    {value: '영선관리', text: '영선관리'},
    {value: '코스관리', text: '코스관리'},
    {value: 'ESG기획', text: 'ESG기획'},
    {value: '기숙사관리', text: '기숙사 관리'},
  ];

  options_jolup: BV_OptionItem[] = [
    {value: '', text: '선택'},
    {value: '재학', text: '재학'},
    {value: '중퇴', text: '중퇴'},
    {value: '검정', text: '검정'},
    {value: '수료', text: '수료'},
    {value: '전문학사졸업', text: '전문학사졸업'},
    {value: '학사졸업', text: '학사졸업'},
    {value: '석사졸업', text: '석사졸업'},
    {value: '박사졸업', text: '박사졸업'},
  ];
  options_fg_nm: BV_OptionItem[] = [
    {value: '신입', text: '신입'},
    {value: '경력', text: '경력'},
  ];
  options_carr_term_dc: BV_OptionItem[] = [
    {value: ' ', text: '선택'},
    {value: '1년미만', text: '1년 미만'},
    {value: '1년이상-3년미만', text: '1년 이상 ~ 3년 미만'},
    {value: '3년이상-5년미만', text: '3년 이상 ~ 5년 미만'},
    {value: '5년이상-10년미만', text: '5년 이상 ~ 10년 미만'},
    {value: '10년이상', text: '10년이상'},
  ];
  userBirthDay: Date = moment().toDate();
  //1로그인 정보입력, 2신규사용자 인증 입력, 4. 상세정보입력페이지
  pageState = 0;
  // pageState = 4;
  tabIndex = 0;
  chkUpload = false;
  chkCollectInfo = false;
  todayDate = moment().format('YYYY년 MM월 DD일');

  private identityAuth = false;
  private host = window.location.protocol + '//' + window.location.host;
  private reqInfo = '';
  private windowRef: any = null;
  private repeatPassword = '';
  private duty_dc1 = '';
  private duty_dc2 = '';
  private duty_dc3 = '';
  private eng_lnm_nm_first = '';
  private eng_lnm_nm_last = '';

  //증명사진
  image = null;
  imageSrc = null;

  mounted(): void {
    // eslint-disable-next-line
    window.recvSirenPhoneResult = this.recvSirenPhoneResult;
  }

  get hasImage() {
    return !!this.image;
  }

  get hasImageSrc() {
    return !!this.imageSrc;
  }

  base64Encode(data: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  @Watch('image', {immediate: true, deep: true})
  public imageChanged(newValue: string, oldValue: string) {
    if (newValue !== oldValue) {
      if (newValue) {
        this.base64Encode(newValue)
          .then(value => {
            this.imageSrc = value as any;
            this.uploadImage();
          })
          .catch(() => {
            this.imageSrc = null;
          });
      } else {
        this.imageSrc = null;
      }
    }
  }
  @Watch('agree4', {immediate: true, deep: true})
  public agree4Changed(newValue: boolean, oldValue: boolean) {
    if (newValue !== oldValue) {
      this.agree1 = newValue;
      this.agree2 = newValue;
      this.agree3 = newValue;
    }
  }

  async getHrImage(file_dc: string) {
    let apiPath = '/getHrImage/' + file_dc;
    let response = await AXIOS.get<any>(apiPath)
      .then(response => {
        if (response.status == 200) {
          this.imageSrc = response.data;
          return;
        }
        this.$bvModal.msgBoxOk('증명사진 로딩실패', {
          title: '증명사진 로딩실패',
          centered: true,
        });
      })
      .catch(error => {
        this.$bvModal.msgBoxOk('증명사진 로딩실패', {
          title: '증명사진 로딩실패',
          centered: true,
        });
      });
  }

  async uploadImage() {
    if (this.image == null) return;
    const formData = new FormData();
    const uploadFile = this.image as unknown as Blob;
    formData.append('file', uploadFile);
    AXIOS.post('/uploadHrDBImage', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        let responseData = response.data;
        console.log(responseData);
        if (response.status == 200) {
          if (responseData.success == 'true') {
            //이미지 등록정보 수정
            const data = responseData.data;
            this.hrDetailDto.file_dc = data.newFilename;
            this.hrDetailDto.originalFilename = data.originalFilename;
            this.hrDetailDto.originalExtension = data.originalExtension;
            this.hrDetailDto.fileCreateDate = data.fileCreateDate;
            this.hrDetailDto.fileSize = data.fileSize;

            return;
          }
        }
        this.$bvModal.msgBoxOk('증명사진 업로드 실패', {
          title: '증명사진 업로드 실패',
          okVariant: 'danger',
          centered: true,
        });
      })
      .catch(error => {
        this.$bvModal.msgBoxOk('증명사진 업로드 실패', {
          title: '증명사진 업로드 실패',
          centered: true,
        });
      });
  }

  hideGuidePage() {
    this.clearDto();
    this.pageState = 1;
  }

  showPasswdInit() {
    this.clearDto();
    this.pageState = -1;
  }

  changePageState(pageNumber: number) {
    this.clearDto();
    this.pageState = pageNumber;
  }

  async addHrUser() {
    const isValid = await this.$refs.observer2.validate();
    if (!isValid) {
      await this.$bvModal.msgBoxOk('비밀번호 규칙에 맞게 입력해주세요', {
        title: '비밀번호 규칙확인',
      });
      return;
    }

    if (this.hrUserDtoNew.kor_nm == '') {
      this.$bvModal.msgBoxOk('핸드폰을 인증해주세요', {
        title: '인증필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    if (this.hrUserDtoNew.email_nm == '') {
      this.$bvModal.msgBoxOk('이메일을 입력해주세요', {
        title: '이메일 인증필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    if (this.hrUserDtoNew.scret_no == '') {
      this.$bvModal.msgBoxOk('비밀번호를 입력해주세요', {
        title: '비밀번호 입력필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    if (this.hrUserDtoNew.scret_no != this.repeatPassword) {
      this.$bvModal.msgBoxOk('비밀번호가 다릅니다', {
        title: '비밀번호 확인필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    let apiPath = '/HR/HRApiX10114Service/person';
    let postData = 'person=' + encodeURIComponent(JSON.stringify(this.hrUserDtoNew));
    AXIOS.post<any>(apiPath, postData)
      .then(response => {
        let data = response.data;
        if (response.status == 200) {
          if (data.rep_cd == 'SUCCESS') {
            //상세정보 표시
            this.hrUserDto = this.hrUserDtoNew;
            this.checkHrUser();
            this.$bvModal.msgBoxOk(data.rep_msg, {
              title: '인재 기본정보 저장완료',
              okVariant: 'success',
              centered: true,
            });
          } else {
            this.$bvModal.msgBoxOk(data.rep_msg, {
              title: 'DB조회 실패',
              okVariant: 'danger',
              centered: true,
            });
          }
        } else {
          this.$bvModal.msgBoxOk(data.rep_msg, {
            title: 'DB조회 실패',
            okVariant: 'danger',
            centered: true,
          });
        }
      })
      .catch(error => {
        this.$bvModal.msgBoxOk('상세정보 저장시 오류발생', {
          title: 'DB저장 실패',
          centered: true,
        });
      })
      .finally(() => {
        this.clearDto();
      });
  }

  async deleteHrUserConfirm() {
    this.$bvModal
      .msgBoxConfirm('저장된 인재DB를 삭제하시겠습니까?', {
        title: '인재DB 삭제',
        okVariant: 'success',
        okTitle: '확인',
        cancelTitle: '취소',
        centered: true,
      })
      .then(async value => {
        if (value === true) {
          await this.deleteHrUser();
          this.clearDto();
        }
      });
  }

  async deleteHrUser() {
    let apiPath = '/HR/HRApiX10114Service/person/delete';
    const jsonStr = '{"jnco_afe_no": "' + this.hrDetailDto.jnco_afe_no + '"}';
    let postData = 'person=' + encodeURIComponent(jsonStr);
    await AXIOS.post<any>(apiPath, postData)
      .then(response => {
        let data = response.data;
        if (response.status == 200) {
          if (data.rep_cd == 'SUCCESS') {
            this.$bvModal.msgBoxOk(data.rep_msg, {
              title: '인재 기본정보 삭제완료',
              okVariant: 'success',
              centered: true,
            });
            this.pageState = 0;
          } else {
            this.$bvModal.msgBoxOk(data.rep_msg, {
              title: '인재 기본정보 삭제 실패',
              okVariant: 'danger',
              centered: true,
            });
          }
        } else {
          this.$bvModal.msgBoxOk(data.rep_msg, {
            title: '인재 기본정보 삭제 실패',
            okVariant: 'danger',
            centered: true,
          });
        }
      })
      .catch(error => {
        this.$bvModal.msgBoxOk('인재 기본정보 삭제 오류발생', {
          title: '인재 기본정보 삭제 실패',
          centered: true,
        });
      })
      .finally(() => {
        this.clearDto();
      });
  }

  retInfo = '';

  recvSirenPhoneResult(type: string, result: BizSirenResultDto, retInfo: string): void {
    this.hrUserDtoNew.kor_nm = result.name;
    this.hrUserDtoNew.biryymm_dt = result.birYMD;
    this.hrUserDtoNew.sex_cd = result.sex;
    this.hrUserDtoNew.hp_no = result.cellNo;

    this.hrUserDtoInitPW.kor_nm = result.name;
    this.hrUserDtoInitPW.biryymm_dt = result.birYMD;
    this.hrUserDtoInitPW.sex_cd = result.sex;
    this.hrUserDtoInitPW.hp_no = result.cellNo;
    this.retInfo = retInfo;

    this.identityAuth = true;
  }

  openPhoneAuthPopup(): void {
    //console.log(this.authPhoneDto);

    //핸드폰 본인인증 창열
    loginService.getBizSirenEncode().then(response => {
      if (response.status == 200) {
        const responseData = response.data;
        this.reqInfo = responseData;
        this.windowRef = window.open(
          '',
          '',
          'width=430, height=560, resizable=1, scrollbars=no, status=0, titlebar=0, toolbar=0, left=300, top=200',
        );
        const popupDocument = this.windowRef.document;
        const frm = popupDocument.createElement('form');
        frm.setAttribute('id', 'auth-form');
        frm.setAttribute('action', 'https://pcc.siren24.com/pcc_V3/jsp/pcc_V3_j10_v2.jsp');
        frm.setAttribute('method', 'post');
        frm.setAttribute('target', '_self');

        const element1 = popupDocument.createElement('input');
        element1.type = 'hidden';
        element1.value = this.reqInfo;
        element1.name = 'reqInfo';
        frm.appendChild(element1);

        const element2 = popupDocument.createElement('input');
        element2.type = 'hidden';
        element2.value = '32' + this.host + '/noHeader/p/bizSirenCallBackPhone';
        element2.name = 'retUrl';
        frm.appendChild(element2);

        const element3 = popupDocument.createElement('input');
        element3.type = 'hidden';
        element3.value = '2';
        element3.name = 'verSion';
        frm.appendChild(element3);

        popupDocument.body.appendChild(frm);
        //this.windowRef.document.body.appendChild(this.$el);
        //copyStyles(window.document, this.windowRef.document);
        //this.windowRef.addEventListener('beforeunload', this.closePopup);
        frm.submit();
      } else {
        //http response 오류 처리
      }
    });
  }

  deleteAcademic(index: number) {
    this.hrDetailDto.academic = this.hrDetailDto.academic.filter((item, i) => i !== index);
  }

  deleteCareer(index: number) {
    this.hrDetailDto.career = this.hrDetailDto.career.filter((item, i) => i !== index);
  }

  deleteCertificate(index: number) {
    this.hrDetailDto.certificate = this.hrDetailDto.certificate.filter((item, i) => i !== index);
  }

  deleteLanguage(index: number) {
    this.hrDetailDto.language = this.hrDetailDto.language.filter((item, i) => i !== index);
  }

  addAcademic() {
    const newRow: HrAcademic = {
      from_dt: '',
      to_dt: '',
      mcls_dc1: '',
      mcls_dc2: '',
      mcls_dc3: '',
      mcls_dc4: '',
    };
    this.hrDetailDto.academic.push(newRow);
  }

  addCareer() {
    const newRow: HrCareer = {
      from_dt: '',
      to_dt: '',
      mcls_dc1: '',
      mcls_dc2: '',
      mcls_dc3: '',
      mcls_dc4: '',
      mcls_dc5: '',
    };
    this.hrDetailDto.career.push(newRow);
  }

  addCertificate() {
    const newRow: HrCertificate = {
      from_dt: '',
      mcls_dc1: '',
      mcls_dc2: '',
      mcls_dc3: '',
    };
    this.hrDetailDto.certificate.push(newRow);
  }

  addLanguage() {
    const newRow: HrLanguage = {
      from_dt: '',
      mcls_dc1: '',
      mcls_dc2: '',
      mcls_dc3: '',
    };
    this.hrDetailDto.language.push(newRow);
  }

  saveHrUser() {
    this.updateHrDetail(false);
  }

  async updateHrDetailConfirm() {
    if (!this.chkUpload) {
      this.$bvModal.msgBoxOk('인재DB 등록 확인 동의 체크를 해주세요', {
        title: '동의필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    await this.updateHrDetail(true);
  }

  async updateHrDetail(isCompleted: boolean) {
    this.showLoadingModal();
    let tempUpdate = true;
    let notInput = '';

    if ((isCompleted && this.duty_dc1 == '') || (this.tabIndex === 0 && this.duty_dc1 == '')) {
      tempUpdate = false;
      notInput = '희망직무1';
    }

    if (
      (isCompleted && this.hrDetailDto.ntnl_nm.trim() == '') ||
      (this.tabIndex === 0 && this.hrDetailDto.ntnl_nm.trim() == '')
    ) {
      tempUpdate = false;
      notInput = '국적';
    }
    if (
      (isCompleted && this.hrDetailDto.fg_nm.trim() == '') ||
      (this.tabIndex === 0 && this.hrDetailDto.fg_nm.trim() == '')
    ) {
      tempUpdate = false;
      notInput = '지원구분';
    }

    if (
      (isCompleted && this.hrDetailDto.fg_nm.trim() === '경력' && this.hrDetailDto.carr_term_dc?.trim() === '') ||
      (this.tabIndex === 0 && this.hrDetailDto.fg_nm.trim() === '경력' && this.hrDetailDto.carr_term_dc?.trim() === '')
    ) {
      tempUpdate = false;
      notInput = '경력기간';
    }

    if (!tempUpdate) {
      this.$bvModal.msgBoxOk(`${notInput}를(을) 입력해주세요`, {
        title: '필수 입력 확인',
        okVariant: 'danger',
        centered: true,
      });
      this.hideLoadingModal();
      return;
    }
    if (this.imageSrc === null) {
      this.$bvModal.msgBoxOk(`증명사진을 입력해주세요`, {
        title: '필수 입력 확인',
        okVariant: 'danger',
        centered: true,
      });
      this.hideLoadingModal();
      return;
    }

    if (
      (isCompleted && this.hrDetailDto.academic.length === 0) ||
      (this.tabIndex === 1 && this.hrDetailDto.academic.length === 0)
    ) {
      this.$bvModal.msgBoxOk(`최종학력을 1개이상 입력주세요`, {
        title: '필수 입력 확인',
        okVariant: 'danger',
        centered: true,
      });
      this.hideLoadingModal();
      return;
    }

    let apiPath = '/HR/HRApiX10114Service/person/detail';
    //희망 직무 편집
    const duty_dc = this.duty_dc1 + ',' + this.duty_dc2 + ',' + this.duty_dc3;
    const name_eng = this.eng_lnm_nm_first + ',' + this.eng_lnm_nm_last;
    this.hrDetailDto.duty_dc = duty_dc;
    this.hrDetailDto.eng_lnm_nm = name_eng;

    let submitDto = JSON.parse(JSON.stringify(this.hrDetailDto));
    //날짜 입력 폼 문자로 변경
    for (let item of submitDto.academic) {
      if (item.from_dt !== '' && item.from_dt !== null) {
        const tempStr = moment(item.from_dt).format('YYYYMMDD');
        item.from_dt = tempStr;
      }
      if (item.to_dt !== '' && item.to_dt !== null) {
        const tempStr = moment(item.to_dt).format('YYYYMMDD');
        item.to_dt = tempStr;
      }
    }
    for (let item of submitDto.academic) {
      if (item.from_dt !== '' && item.from_dt !== null) {
        const tempStr = moment(item.from_dt).format('YYYYMMDD');
        item.from_dt = tempStr;
      }
      if (item.to_dt !== '' && item.to_dt !== null) {
        const tempStr = moment(item.to_dt).format('YYYYMMDD');
        item.to_dt = tempStr;
      }
    }
    for (let item of submitDto.career) {
      if (item.from_dt !== '' && item.from_dt !== null) {
        const tempStr = moment(item.from_dt).format('YYYYMMDD');
        item.from_dt = tempStr;
      }
      if (item.to_dt !== '' && item.to_dt !== null) {
        const tempStr = moment(item.to_dt).format('YYYYMMDD');
        item.to_dt = tempStr;
      }
    }
    for (let item of submitDto.certificate) {
      if (item.from_dt !== '' && item.from_dt !== null) {
        const tempStr = moment(item.from_dt).format('YYYYMMDD');
        item.from_dt = tempStr;
      }
    }
    for (let item of submitDto.language) {
      if (item.from_dt !== '' && item.from_dt !== null) {
        const tempStr = moment(item.from_dt).format('YYYYMMDD');
        item.from_dt = tempStr;
      }
    }
    //국적 공백일경우 스페이스 - null저장시 오류발생
    if (submitDto.ntnl_nm === '') submitDto.ntnl_nm = ' ';

    let postData = 'person=' + encodeURIComponent(JSON.stringify(submitDto));
    await AXIOS.post<any>(apiPath, postData)
      .then(async response => {
        let data = response.data;
        if (response.status == 200) {
          if (data.rep_cd == 'SUCCESS') {
            //상세정보 표시
            await this.$bvModal.msgBoxOk(data.rep_msg, {
              title: '상세정보 저장완료',
              okVariant: 'success',
              centered: true,
            });
            if (isCompleted) {
              this.clearDto();
              this.pageState = 0;
            }
          } else {
            this.$bvModal.msgBoxOk(data.rep_msg, {
              title: 'DB저장 실패',
              okVariant: 'danger',
              centered: true,
            });
          }
        } else {
          this.$bvModal.msgBoxOk(data.rep_msg, {
            title: 'DB저장 실패',
            okVariant: 'danger',
            centered: true,
          });
        }
      })
      .catch(error => {
        this.$bvModal.msgBoxOk('상세정보 저장시 오류발생', {
          title: 'DB저장 실패',
          centered: true,
        });
      });
    this.hideLoadingModal();
  }

  checkPrivacy() {
    if (!this.agree1 || !this.agree2 || !this.agree3) {
      this.$bvModal.msgBoxOk('개인정보 수집에 동의 체크를 해주세요', {
        title: '동의필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    this.pageState = 3;
    this.clearDto();
  }

  goToPrivacyPage() {
    this.$router.push('/p/Pinxpolicy/0');
  }

  change_fg_nm() {
    if (this.hrDetailDto.fg_nm === '신입') {
      //공백, null로하면 에러발생
      this.hrDetailDto.carr_term_dc = ' ';
    }
  }

  async checkHrUser() {
    //생일업데이트
    this.showLoadingModal();
    let strPerson = encodeURIComponent(JSON.stringify(this.hrUserDto));
    // let strPerson =
    // 	'%7B%22kor_nm%22%3A%20%22%EC%8B%A0%EC%8B%9C%EC%95%84%22%2C%22sex_cd%22%3A%22f%22%2C%22email_nm%22%3A%22cynthia%40gmail.com%22%2C%22hp_no%22%3A%20%22010-3333-5555%22%2C%22biryymm_dt%22%3A%2219900517%22%2C%22scret_no%22%3A%223lwklskdfjadl%22%7D';
    let apiPath = '/HR/HRApiX10114Service/person?person=' + strPerson;
    await AXIOS.get<any>(apiPath)
      .then(response => {
        let data = response.data;
        if (response.status == 200) {
          if (data.rep_cd == 'SUCCESS' || data.rep_cd == 'SUCCESS_TEMP') {
            this.clearDto();
            //상세정보 표시
            const recvHrDetail = data.data;
            for (let item of recvHrDetail.academic) {
              console.log(item);
              if (item.from_dt !== '') {
                const tempStr = moment(item.from_dt, 'YYYYMMDD').toDate();
                item.from_dt = tempStr;
              }
              if (item.to_dt !== '') {
                const tempStr = moment(item.to_dt, 'YYYYMMDD').toDate();
                item.to_dt = tempStr;
              }
              console.log(item);
            }
            for (let item of recvHrDetail.career) {
              console.log(item);
              if (item.from_dt !== '') {
                const tempStr = moment(item.from_dt, 'YYYYMMDD').toDate();
                item.from_dt = tempStr;
              }
              if (item.to_dt !== '') {
                const tempStr = moment(item.to_dt, 'YYYYMMDD').toDate();
                item.to_dt = tempStr;
              }
              console.log(item);
            }
            for (let item of recvHrDetail.certificate) {
              console.log(item);
              if (item.from_dt !== '') {
                const tempStr = moment(item.from_dt, 'YYYYMMDD').toDate();
                item.from_dt = tempStr;
              }
              if (item.to_dt !== '') {
                const tempStr = moment(item.to_dt, 'YYYYMMDD').toDate();
                item.to_dt = tempStr;
              }
              console.log(item);
            }
            for (let item of recvHrDetail.language) {
              console.log(item);
              if (item.from_dt !== '') {
                const tempStr = moment(item.from_dt, 'YYYYMMDD').toDate();
                item.from_dt = tempStr;
              }
              if (item.to_dt !== '') {
                const tempStr = moment(item.to_dt, 'YYYYMMDD').toDate();
                item.to_dt = tempStr;
              }
              console.log(item);
            }

            this.hrDetailDto = recvHrDetail as HrDetail;
            console.log(this.hrDetailDto);
            this.hrDetailDto.jnco_afe_no = recvHrDetail.jnco_afe_no;
            if (recvHrDetail.file_dc.trim() != '') {
              this.getHrImage(recvHrDetail.file_dc);
            } else {
              this.imageSrc = null;
            }
            const duty_dc = recvHrDetail.duty_dc as string;
            if (duty_dc.indexOf(',') > 0) {
              const words = duty_dc.split(',');
              if (words.length == 3) {
                this.duty_dc3 = words[2];
                this.duty_dc2 = words[1];
                this.duty_dc1 = words[0];
              }
            }

            const name_eng = recvHrDetail.eng_lnm_nm as string;
            if (name_eng.indexOf(',') > 0) {
              const words = name_eng.split(',');
              if (words.length == 2) {
                this.eng_lnm_nm_last = words[1];
                this.eng_lnm_nm_first = words[0];
              }
            }
            //임시비빌번호 로그인일경우 비번변경페이지로 이동
            if (data.rep_cd === 'SUCCESS_TEMP') {
              this.pageState = -11;
            } else {
              this.pageState = 4;
            }
          } else {
            if (data.rep_cd == 'NONE EXIST') {
              this.$bvModal
                .msgBoxConfirm('입력한 정보로 등록된 인재DB가 없습니다.\n' + '신규로 등록하시겠습니까?', {
                  title: '인재DB 제출확인',
                  okVariant: 'success',
                  okTitle: '등록하기',
                  cancelTitle: '취소',
                  centered: true,
                })
                .then(value => {
                  if (value === true) {
                    this.pageState = 2;
                    this.clearDto();
                  }
                });
            } else {
              this.$bvModal.msgBoxOk(data.rep_msg, {
                title: 'DB조회 실패',
                okVariant: 'danger',
                centered: true,
              });
            }
          }
        } else {
          this.$bvModal.msgBoxOk(data.rep_msg, {
            title: 'DB조회 실패',
            okVariant: 'danger',
            centered: true,
          });
        }
      })
      .catch(error => {
        this.$bvModal.msgBoxOk('DB확인중 오류발생', {
          title: 'DB확인중 오류발생',
          centered: true,
        });
      })
      .finally(() => {
        this.clearDto();
      });

    this.hideLoadingModal();
  }

  async initPWHrUser() {
    if (this.hrUserDtoInitPW.kor_nm == '') {
      this.$bvModal.msgBoxOk('본인인증이 필요합니다', {
        title: '본인인증 필요',
        okVariant: 'danger',
        centered: true,
      });
      return;
    }
    //생일업데이트
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    let strPerson = encodeURIComponent(JSON.stringify(this.hrUserDtoInitPW));
    // let strPerson =
    // 	'%7B%22kor_nm%22%3A%20%22%EC%8B%A0%EC%8B%9C%EC%95%84%22%2C%22sex_cd%22%3A%22f%22%2C%22email_nm%22%3A%22cynthia%40gmail.com%22%2C%22hp_no%22%3A%20%22010-3333-5555%22%2C%22biryymm_dt%22%3A%2219900517%22%2C%22scret_no%22%3A%223lwklskdfjadl%22%7D';
    let apiPath = '/HR/HRApiX10114Service/person/find?person=' + strPerson;
    AXIOS.get<any>(apiPath)
      .then(response => {
        let data = response.data;
        if (response.status == 200) {
          if (data.rep_cd == 'SUCCESS') {
            //비번초기화 페이지 실행
            this.$bvModal.msgBoxOk(
              self.htmlToVNode(
                '등록된 이메일로 임시 비밀번호를 전송하였습니다.<br>해당 비밀번호로 로그인 후 비밀번호를 변경하세요.',
              ),
              {
                title: '비밀번호 찾기',
                okVariant: 'success',
                centered: true,
              },
            );
            this.clearDto();
            this.pageState = 0;
          } else {
            this.$bvModal.msgBoxOk(data.rep_msg, {
              title: 'DB조회 실패',
              okVariant: 'danger',
              centered: true,
            });
          }
        } else {
          this.$bvModal.msgBoxOk(data.rep_msg, {
            title: 'DB조회 실패',
            okVariant: 'danger',
            centered: true,
          });
        }
      })
      .catch(error => {
        this.$bvModal.msgBoxOk('DB확인중 오류발생', {
          title: 'DB확인중 오류발생',
          centered: true,
        });
      })
      .finally(() => {
        this.clearDto();
      });
  }

  // async changePW(jnco_afe_no: string) {
  //   // eslint-disable-next-line @typescript-eslint/no-this-alias
  //   const self = this;
  //   let apiPath = '/hrChangePW/' + jnco_afe_no;
  //   let response = await AXIOS.get<any>(apiPath)
  //     .then(response => {
  //       let data = response.data;
  //       if (response.status == 200) {
  //         if (data.rep_cd == 'SUCCESS') {
  //           //비번초기화 페이지 실행
  //           //jnco_afe_no조회
  //           console.log(data);
  //
  //           );
  //           this.pageState = 0;
  //         } else {
  //           this.$bvModal.msgBoxOk(data.rep_msg, {
  //             title: 'DB조회 실패',
  //             okVariant: 'danger',
  //             centered: true,
  //           });
  //         }
  //       } else {
  //         this.$bvModal.msgBoxOk(data.rep_msg, {
  //           title: 'DB조회 실패',
  //           okVariant: 'danger',
  //           centered: true,
  //         });
  //       }
  //     })
  //     .catch(error => {
  //       this.$bvModal.msgBoxOk('DB확인중 오류발생', {
  //         title: 'DB확인중 오류발생',
  //         centered: true,
  //       });
  //     });
  // }

  newPassword = '';
  newPasswordRepeat = '';

  async ChangeHrDBPW() {
    const isValid = await this.$refs.observer.validate();
    if (!isValid) {
      await this.$bvModal.msgBoxOk('비밀번호 규칙에 맞게 입력해주세요', {
        title: '비밀번호 규칙확인',
      });
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const jsonData = {
      jnco_afe_no: this.hrDetailDto.jnco_afe_no,
      scret_no: this.newPassword,
    };
    let apiPath = '/HR/HRApiX10114Service/person/change';
    let postData = 'person=' + encodeURIComponent(JSON.stringify(jsonData));

    let response = await AXIOS.post<any>(apiPath, postData)
      .then(response => {
        let data = response.data;
        if (response.status == 200) {
          if (data.rep_cd == 'SUCCESS') {
            //비번초기화 페이지 실행
            //jnco_afe_no조회
            console.log(data);
            this.$bvModal.msgBoxOk(self.htmlToVNode('비밀번호를 변경하였습니다<br>새로운 비밀번호로 로그인해주세요'), {
              title: '비밀번호 변경성공',
              okVariant: 'success',
              centered: true,
            });
            this.pageState = 0;
            this.clearDto();
          } else {
            this.$bvModal.msgBoxOk(data.rep_msg, {
              title: '비밀번호 변경 실패',
              okVariant: 'danger',
              centered: true,
            });
          }
        } else {
          this.$bvModal.msgBoxOk(data.rep_msg, {
            title: '비밀번호 변경 실패',
            okVariant: 'danger',
            centered: true,
          });
        }
      })
      .catch(error => {
        this.$bvModal.msgBoxOk('비밀번호 변경 오류발생', {
          title: '비밀번호 변경 오류발생',
          centered: true,
        });
      });
  }

  clearDto() {
    this.hrUserDtoNew.kor_nm = '';
    this.hrUserDtoNew.biryymm_dt = '';
    this.hrUserDtoNew.email_nm = '';
    this.hrUserDtoNew.sex_cd = '';
    this.hrUserDtoNew.scret_no = '';
    this.hrUserDtoNew.hp_no = '';

    // this.hrUserDto.kor_nm = '';
    // this.hrUserDto.biryymm_dt = '';
    // this.hrUserDto.email_nm = '';
    // this.hrUserDto.sex_cd = '';
    // this.hrUserDto.scret_no = '';
    // this.hrUserDto.hp_no = '';

    this.hrUserDtoInitPW.kor_nm = '';
    this.hrUserDtoInitPW.biryymm_dt = '';
    this.hrUserDtoInitPW.email_nm = '';
    this.hrUserDtoInitPW.sex_cd = '';
    this.hrUserDtoInitPW.hp_no = '';
    this.identityAuth = false;

    this.newPassword = '';
    this.newPasswordRepeat = '';
    this.chkCollectInfo = false;
    this.chkUpload = false;
  }
}

<template lang="html">
  <div class="d-flex flex-column">
    <b-form-file
      v-if="!noUpload"
      multiple
      no-drop
      placeholder="선택된 파일이 없습니다."
      :accept="accept"
      :value="uploading"
      @input="uploadingChanged"
      browse-text="찾아보기"
      :capture="displayImage"
    ></b-form-file>
    <div class="d-flex flex-wrap">
      <div v-for="(item, idx) in uploaded" :key="idx" class="mx-2 d-flex flex-column">
        <div class="d-flex align-items-center">
          <p class="m-0 p-0 text-nowrap">{{ item.realFileName }}</p>
          <b-icon
            v-show="!noUpload"
            variant="primary"
            icon="x"
            font-scale="2"
            @click="$emit('deleteUploaded', idx)"
          ></b-icon>
        </div>
        <img
          v-if="displayImage"
          :src="item.thumbnailPic"
          :style="`width:${thumbnailSize}px;height:${thumbnailSize}px`"
          class="thumbnail"
        />
      </div>

      <div v-if="displayImage" class="d-flex flex-wrap">
        <div v-for="(item, idx) in pics" :key="idx" class="mx-2 d-flex flex-column">
          <div class="d-flex align-items-center">
            <p class="m-0 p-0 text-nowrap">{{ uploading[idx].name }}</p>
            <b-icon variant="primary" icon="x" font-scale="2" @click="delete_file(idx)"></b-icon>
          </div>
          <img :src="item" :style="`width:${thumbnailSize}px;height:${thumbnailSize}px`" class="thumbnail" />
        </div>
      </div>
      <div v-else class="d-flex flex-wrap">
        <div v-for="(item, idx) in uploading" :key="idx" class="mx-2 d-flex align-items-center">
          <p class="m-0 p-0 text-nowrap">{{ item.name }}</p>
          <b-icon variant="primary" icon="x" font-scale="2" @click="delete_file(idx)"></b-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormAttachHrFiles',
  props: {
    value: {
      type: Array,
      required: true,
    },
    uploaded: {
      type: Array,
    },
    displayImage: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '.jpg, .png, .gif, .pdf',
    },
    thumbnailSize: {
      type: Number,
      default: 100,
    },
    noUpload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // displayImage 일 경우 img src로 표시하기 위해 로딩된 이미지
      uploading: [], // (사진) 파일 업로드 (아직 업로드 안된 것)
      // uploaded: [], // (사진) 이미 업로드된 파일들 (수정 - AttachFileInfo)
      pics: [],
    };
  },
  // async created() {},
  methods: {
    clear() {
      this.uploading = [];
      this.$emit('input', this.uploading);
      this.pics = [];
    },
    loadImageData(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
      });
    },
    uploadingChanged(files) {
      // console.log(files);
      for (let i = 0; i < files.length; i++) {
        const filesize = (files[i].size / 1024 / 1024).toFixed(4); // MB

        if (files[i].name !== 'item' && typeof files[i].name !== 'undefined' && filesize > 10) {
          // console.log('100메가 이상');
          this.$bvModal.msgBoxOk('10M 이하의 파일만 첨부 가능합니다.', {
            okVariant: 'danger',
            centered: true,
          });
          files[i] = null;
          return;
        }
      }

      this.uploading = files;
      // console.log(this.uploading);
      if (this.displayImage) {
        this.pics = [];
        this.uploading.map(x =>
          this.loadImageData(x).then(loadedData => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            ctx.canvas.width = this.thumbnailSize;
            ctx.canvas.height = this.thumbnailSize;
            const image = new Image();
            image.onload = () => {
              ctx.drawImage(
                image,
                0,
                0,
                image.width,
                image.height, // source rectangle
                0,
                0,
                canvas.width,
                canvas.height,
              );
              this.pics.push(canvas.toDataURL());
              this.$emit('thumbnailAdded', this.pics);
            };
            image.src = loadedData;
          }),
        );
      }
      this.$emit('input', files);
    },
    delete_file(idx) {
      // console.log(this.fileUploadPic);
      // this.$emit('deleteUploading', idx);
      this.value.splice(idx, 1);
      this.pics.splice(idx, 1);
    },
  },
};
</script>
